<template>
  <div class="reject-template_main">
    <div class="reject-header">
      <div class="reject-header_title">您的反馈对我们很重要</div>
      <div class="reject-header_tip">请告诉我们您没有接受邀请的原因：</div>
    </div>
    <div class="reject-content">
      <div v-for="(item, index) in list" :key="index" class="reject-content_item" :class="item.label === '其他' ? 'other-reason' : ''">
        <van-checkbox v-model="item.value" icon-size="14px" shape="square">{{ item.label }}</van-checkbox>
        <van-field v-model="item.reasonRemark" :disabled="!item.value" autosize placeholder="请输入其他原因" maxlength="50" show-word-limit v-if="item.label === '其他'"></van-field>
      </div>
    </div>
    <div class="reject-footer">
      <!-- <van-button type="default" size="small">取 消</van-button> -->
      <van-button @click="submitRefuseReason" :disabled="getSubmitBtnStatus" type="info" size="small">提 交</van-button>
    </div>
  </div>
</template>
<script>
import { submitRefuseReason, submitAiIntention } from '@/api/candidate'
export default {
  data() {
    return {
      list: [],
      relateType: ['interviewId', 'interviewPlanId', 'offerId'],
    }
  },
  props: {
    reasonTypeList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  watch: {
    reasonTypeList: {
      immediate: true,
      deep: true,
      handler() {
        this.list = this.reasonTypeList.map(i => {
          return { value: false, reasonId: i.id, label: i.reasonName, reasonRemark: undefined }
        })
      },
    },
  },
  methods: {
    submit() {
      const { rejectType } = this.$route.query
      if (rejectType === '0') {
        this.submitAiIntention()
      } else {
        this.submitRefuseReason()
      }
    },
    submitRefuseReason() {
      const list = this.list.filter(i => {
        return i.value
      })

      const reasonDetails = list.map(v => {
        return { reasonId: v.reasonId, reasonRemark: v.reasonRemark }
      })
      const query = this.$route.query
      const relateId = query.relateId
      const reasonType = +query.rejectType
      const data = {
        relateId,
        reasonType,
        reasonDetails,
        jobseekerChannelId: this.$route.query.jobseekerChannelId,
      }
      // this.$toast.success('成功文案')
      submitRefuseReason(data).then(res => {
        if (res && res.code === 0) {
          this.$toast.success('提交成功')
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    submitAiIntention() {
      const { interviewId, jobseekerInterviewId } = this.$route.query
      console.log('jobseekerInterviewId', jobseekerInterviewId)
      if (jobseekerInterviewId || interviewId) {
        submitAiIntention({
          interviewId: jobseekerInterviewId || interviewId,
          aiIntention: 0,
        }).then(() => {
          this.submitRefuseReason()
        })
      }
    },
  },
  computed: {
    getSubmitBtnStatus() {
      const list = this.list.filter(i => {
        return i.value
      })
      if (list && list.length) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.reject-template_main {
  padding: 25px 35px 15px 35px;
  width: 560px;
  border-radius: 5px;
  background: #ffffff;
  .reject-header {
    border-bottom: 1px dashed rgba(98, 112, 152, 0.15);
    margin-bottom: 20px;
    .reject-header_title {
      height: 33px;
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 33px;
      margin-bottom: 6px;
    }
    .reject-header_tip {
      height: 33px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #aaaaaa;
      line-height: 33px;
      margin-bottom: 6px;
    }
  }
  .reject-content {
    padding-bottom: 21px;
    border-bottom: 1px dashed rgba(98, 112, 152, 0.15);
    .reject-content_item {
      margin-bottom: 10px;
      line-height: 20px;
      /deep/ .van-checkbox {
        align-items: baseline;
        .van-checkbox__icon {
          .van-icon {
            border-radius: 2px;
          }
        }
        .van-checkbox__label {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }
    .other-reason {
      display: flex;
      align-items: center;
      position: relative;
      /deep/ .van-checkbox {
        min-width: 60px;
      }
      /deep/.van-cell {
        padding: 0px;
        border-bottom: 1px solid #dddddd;
        .van-cell__value {
          display: flex;
          justify-content: space-between;
          .van-field__body {
            width: 100%;
          }
        }
      }
    }
  }
  .reject-footer {
    text-align: right;
    padding-top: 20px;
    .van-button {
      width: 82px;
      // height: 36px;
      // max-height: 36px;
    }
    .van-button + .van-button {
      margin-left: 16px;
    }
  }
}
</style>
