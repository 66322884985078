<template>
  <div class="email-feedback_layout">
    <AcceptTemplate :list="acceptTemplateList" v-if="showAccept" />
    <RejectTemplate :reasonTypeList="reasonTypeList" v-if="showReject" />
  </div>
</template>
<script>
import AcceptTemplate from './common/accept-template.vue'
import RejectTemplate from './common/reject-template.vue'
import { queryMessageTemplateField, queryOfferSendRecord, queryRefuseReason } from '@/api/candidate'
import { queryInterviewPlan } from '@/api/report'
import { cloneDeep } from 'lodash'
export default {
  data() {
    return {
      acceptTemplateList: [],
      offerFieldList: [],
      offerRecord: {},
      offerDataMap: {},
      interviewPlan: {},
      interviewPlanKeys: [
        { key: 'companyName', label: '公司名称', value: '' },
        { key: 'jobTitle', label: '职位名称', value: '' },
        { key: 'interviewTime', label: '面试时间', value: '' },
        { key: 'offlineLocation', label: '面试地点', value: '' },
      ],
      reasonTypeList: [],
      showReject: false,
      showAccept: false,
      loading: false,
    }
  },
  components: { AcceptTemplate, RejectTemplate },
  mounted() {
    this.initQuery()
  },
  methods: {
    initQuery() {
      const query = this.$route.query
      console.log('query', query)
      // acceptType
      if (+query.acceptType === 1) {
        //offer接受
        this.initOffer()
        this.showAccept = true
        this.showReject = false
      } else if (+query.acceptType === 2) {
        //安排面试接受
        this['initInterviewPlan']()
        this.showAccept = true
        this.showReject = false
      } else if (+query.acceptType === 3) {
        //AI面试接受
        this.showAccept = true
        this.showReject = false
      } else if (query.rejectType) {
        //拒绝
        this.showAccept = false
        this.showReject = true
        this.initReject()
      }
    },
    initReject() {
      this.queryRefuseReason()
    },
    queryRefuseReason() {
      queryRefuseReason({ reasonType: +this.$route.query.rejectType }).then(res => {
        this.reasonTypeList = res.data || []
      })
    },
    initOffer() {
      this.loading = true
      this.offerFieldList = []
      this.offerRecord = {}
      Promise.all([this.queryOfferField(), this.queryOfferRecord()])
        .then(() => {
          this.initOfferDataMap()
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    initInterviewPlan() {
      this.queryInterviewPlan()
    },
    queryInterviewPlan() {
      this.loading = true
      const data = {
        id: this.$route.query.interviewPlanId,
      }
      queryInterviewPlan(data).then(res => {
        this.loading = false
        const interviewPlans = res.result || []
        const interviewPlanKeys = cloneDeep(this.interviewPlanKeys)
        console.log('interviewPlans', interviewPlans)
        this.interviewPlan = interviewPlans[0]
        //安排面试已取消
        if (this.interviewPlan.planRoomType === 1) {
          interviewPlanKeys.push({ key: 'candidatePassword', label: '面试密码', value: '' })
        }
        this.acceptTemplateList = interviewPlanKeys.map(item => {
          return {
            value: this.getInterviewValue(item),
            label: this.getInterviewLabel(item),
          }
        })
      })
    },
    getInterviewValue(item) {
      // console.log(item)
      if (item.key === 'interviewTime') {
        return this.interviewPlan.interviewDate + ' ' + this.interviewPlan.interviewStartTime + '-' + this.interviewPlan.interviewEndTime
      } else if (item.key === 'offlineLocation') {
        return this.interviewPlan.planRoomType === 0
          ? this.interviewPlan.offlineLocation
          : this.interviewPlan.planRoomType === 1
          ? this.interviewPlan.candidatePcInterviewUrl
          : this.interviewPlan.planRoomType === 3
          ? this.interviewPlan.tencentMeetingUrl
          : ''
      } else {
        return this.interviewPlan[item.key]
      }
    },
    getInterviewLabel(item) {
      if (item.key === 'offlineLocation' && this.interviewPlan.planRoomType !== 0) {
        return '面试链接'
      } else {
        return item.label
      }
    },

    queryOfferField() {
      const data = {
        functionType: 2,
        companyId: this.$route.query.companyId,
      }
      return queryMessageTemplateField(data).then(res => {
        if (res.code === 0) {
          this.offerFieldList = res.result || []
        }
      })
    },
    queryOfferRecord() {
      return queryOfferSendRecord({
        positionId: this.$route.query.positionId,
        jobseekerChannelId: this.$route.query.jobseekerChannelId,
      }).then(res => {
        this.offerRecord = (res.result || [])[0] || {}
      })
    },
    initOfferDataMap() {
      const content = this.offerRecord.custContent
      let custContent = []
      if (content) {
        custContent = JSON.parse(content)
      }
      custContent.forEach(item => {
        this.offerDataMap[item.fieldId] = item.fieldValue
      })
      const displayList = this.offerFieldList.filter(i => {
        //返回展示的数据
        return i.displayFlag === 1
      })
      this.acceptTemplateList = displayList.map(item => {
        return { label: item.fieldName, value: this.getOfferValue(item.fieldCode) }
      })
    },
    getOfferValue(fieldId) {
      if (fieldId === 'employmentSalary' && this.offerDataMap['employmentSalary']) {
        const employsalary = this.offerDataMap['employmentSalary']
        return `${employsalary.split(':')[0] === 'month' ? '月薪' : '年薪'} : ${employsalary.split(':')[1]}`
      } else if (fieldId === 'probationSalary' && this.offerDataMap['probationSalary']) {
        const probationsalary = this.offerDataMap['probationSalary']
        return `${probationsalary.split(':')[0] === 'month' ? '月薪' : '年薪'} : ${probationsalary.split(':')[1]}`
      } else {
        return this.offerDataMap[fieldId] || ''
      }
    },
  },
  watch: {
    // offerRecord: {
    //   deep: true,
    //   immediate: true,
    //   handler() {
    //     this.initOfferDataMap()
    //   },
    // },
  },
  computed: {},
}
</script>
<style lang="scss" scoped>
.email-feedback_layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f5f5f5;
}
</style>
