<template>
  <div class="accept">
    <van-loading v-if="loading" type="spinner" vertical color="#1989fa" text-color="#1989fa">
      加载中...
    </van-loading>
    <div v-else>
      <img
        class="logo"
        :class="acceptType === '1' ? 'offer-logo' : ''"
        :src="acceptType === '1' ? require('@/assets/images/accept/accept-offer.png') : require('@/assets/images/accept/accept-interview.png')"
      />
      <div class="accept-title">
        <img src="@/assets/images/accept/accept-icon.png" />
        <div class="title">{{ acceptTitle }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { submitAiIntention } from '@/api/candidate'
export default {
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    acceptTitle() {
      const acceptType = this.$route.query.acceptType
      return acceptType === '1' ? '录用已接受' : acceptType === '2' ? '面试已接受' : acceptType === '3' ? 'AI面试已接受' : '面试已接受'
    },
    acceptType() {
      return this.$route.query?.acceptType
    },
  },
  mounted() {
    console.log('mounted', this.acceptType === '3')
    if (this.acceptType === '3') {
      this.submitAiIntention()
    } else {
      this.loading = false
    }
  },
  methods: {
    submitAiIntention() {
      const { interviewId, jobseekerInterviewId } = this.$route.query
      console.log('jobseekerInterviewId', jobseekerInterviewId)
      if (jobseekerInterviewId || interviewId) {
        submitAiIntention({
          interviewId: jobseekerInterviewId || interviewId,
          aiIntention: 1,
        }).then(() => {
          this.loading = false
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.accept {
  .logo {
    width: 295px;
  }
  .offer-logo {
    width: 241px;
  }
  .accept-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    img {
      margin-right: 10px;
      width: 22px;
    }
    .title {
      font-size: 16px;
      color: #333333;
      // line-height: 22px;
    }
  }
}
</style>
